<template>
    <div ref="uiSelectMultiple" class="ui-select-multiple" v-click-outside="clickOutside" :class="$attrs.class">
        <button 
            type="button"
            class="ui-select-multiple__container" 
            :class="{
                'ui-select-multiple__container--valid': field && field.$dirty && !field.$error,
                'ui-select-multiple__container--invalid': field && field.$dirty && field.$error,
                'ui-select-multiple__container--showing-tags': new_value.length,
            }"
            :disabled="loading || disabled"
            @click="focusInput"
            @keyup.enter="focusInput"
        >
            <div v-if="loading" class="py-4 text-center loading">
                <div class="spinner-border" style="width: 12px; height: 12px" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div class="ui-select-multiple__value" :class="{ 'ui-select-multiple__value--focus': new_value.length }">
                <div class="ui-select-multiple__label" :class="{ 'ui-select-multiple__label--focus': new_value.length }">
                    {{ label }}
                </div>
                <div v-if="taggable">
                    <div v-if="selectedOptions.length" class="ui-select-multiple__tags-container">
                        <ui-badge v-for="(option, key) in selectedOptions" :key="key" class="ui-select-multiple__tag" variant="primary">
                            {{ option[optionLabel] }}
                            <button class="ui-select-multiple__remove-tag-button" @click="setValue(option)" :disabled="disabled">
                                <svg width="18" height="18" viewBox="0 0 20 20">
                                    <path
                                        d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                                        stroke="currentColor"
                                        fill="none"
                                        fill-rule="evenodd"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>
                                </svg>
                            </button>
                        </ui-badge>
                    </div>
                </div>
                <div v-else>
                    <div class="ui-select-multiple__tags-container">
                        {{ selectedOptions.length ? selectedOptions : '' }}
                    </div>
                </div>
            </div>
            <div class="ui-select-multiple__right-content">
                <button v-if="new_value !=0" class="ui-select-multiple__clear-button" @click="clearSelectedOptions" :disabled="disabled">
                    <svg width="18" height="18" viewBox="0 0 20 20">
                        <path
                            d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                            stroke="currentColor"
                            fill="none"
                            fill-rule="evenodd"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </button>
                <span class="ui-select-multiple__arrow fas fa-chevron-down"></span>
            </div>
        </button>
        <div v-show="showList" class="ui-select-multiple__list-container">
            <div class="mb-2">
                <input
                    ref="inputSearch"
                    v-model="text"
                    class="ui-select-multiple__search-input"
                    type="text"
                    placeholder="Search"
                    autocomplete="off"
                />
            </div>
            <div v-if="!options || !options.length" class="py-3 text-center text-muted">
                No options available
            </div>
            <div v-else-if="!filteredOptions.length" class="p-3">
                <p class="mb-0 text-muted">There is no option that matches your search</p>
            </div>
            <ul v-show="filteredOptions.length" ref="options" class="ui-select-multiple__list">
                <li v-for="item in filteredOptions" :key="item[optionValue]">
                    <button
                        type="button"
                        class="ui-select-multiple__option"
                        :class="{ 'ui-select-multiple__option--selected': new_value.includes(item[optionValue]) }"
                        @click="setValue(item)"
                    >
                        <div class="ui-select-multiple__checkbox-container">
                            <span v-if="new_value.includes(item[optionValue])">
                                <span class="fas fa-check-square"></span>
                            </span>
                            <span v-else>
                                <span class="far fa-square"></span>
                            </span>
                        </div>
                        <span class="ui-select-multiple__option-label">
                            {{ item[optionLabel] }}
                        </span>
                    </button>
                </li>
            </ul>
            <div class="ui-select-multiple__button-container">
                <ui-button class="w-100" variant="primary" padding-variant="sm" @click="clickOutside">
                    Done
                </ui-button>
            </div>
        </div>
        <div v-if="field && field.$error" class="ui-input__error">
            {{ field.$errors[0].$message }}
        </div>
    </div>
</template>

<script>
import UiBadge from '@/components/ui/Badge.vue';
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    name: 'SelectMultiple',
    components: {
        UiBadge,
        UiButton,
    },
    inheritAttrs: false,
    props: {
        field: {
            type: Object,
            default: null,
            required: false,
        },
        label: {
            type: String,
            default: 'Choose an option',
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        optionLabel: {
            type: [Array, String],
            default: 'label',
            required: false,
        },
        options: {
            type: Array,
            default: () => [],
            required: false,
        },
        optionValue: {
            type: String,
            default: 'value',
            required: false,
        },
        state: {
            type: Boolean,
            default: null,
            required: false,
        },
        taggable: {
            type: Boolean,
            default: null,
            required: false,
        },
        value: {
            type: [String, Array, Object, Number],
            default: null,
            required: false,
        },
    },
    data() {
        return {
            text: '',
            showList: false,
            new_value: [],
        };
    },
    computed: {
        filteredOptions() {
            if (this.text) {
                return this.options.filter(option => {
                    const label = option[this.optionLabel] || '';
                    const regex = new RegExp(this.text, 'ig');
                    return label.match(regex);
                });
            }
            return this.options;
        },

        selectedOptions() {
            if(this.taggable) {
                return this.options.filter(option => this.new_value.includes(option[this.optionValue]));
            }else {
                return this.new_value.length != 0 ? `${this.new_value.length} selected` : '';
            }
        },
    },
    mounted() {
        this.initializeValue();
    },
    methods: {
        clickOutside() {
            this.showList = false;
        },
        focusInput() {
            this.showList = true;
            this.text = '';

            setTimeout(() => {
                this.$refs.inputSearch.focus();
            }, 100);
        },
        initializeValue() {
            if (this.value !== null && this.value !== undefined && this.value !== '') {
                this.new_value = Array.isArray(this.value) ? [...this.value] : this.value.split(',');
                const formattedValue = this.new_value.join(',');
                this.$emit('update:value', formattedValue);
            }
        },
        setValue(option) {
            this.new_value = Array.from(this.new_value || []);

            const optionValue = option[this.optionValue];

            const isSelected = this.new_value.includes(optionValue);

            if (isSelected) {
                const indexToRemove = this.new_value.indexOf(optionValue);
                this.new_value.splice(indexToRemove, 1);
            } else {
                this.new_value.push(optionValue);
            }
            const formattedValue = this.new_value.join(',');
            this.$emit('update:value', formattedValue);
        },
        clearSelectedOptions() {
            this.new_value= [];
            this.$emit('update:value', this.new_value);
        },
    },
}
</script>

<style lang="scss">
.ui-select-multiple {
    position: relative;
    min-width: 120px;
    max-width: 100%;

    &__container {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        gap: 4.5px;
        height: 38px;
        position: relative;
        padding: 4.5px 8px 4.5px 8px;
        width: 100%;

        &:focus-within {
            border-color: $ecart-secondary-200;
            box-shadow: none;
        }

        &:disabled {
            background-color: $ecart-secondary-100;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }

        &--has-prepend {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &--showing-tags {
            height: auto;
        }
    }

    &__arrow {
        color: $ecart-accent;
        content: '\f078';
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-size: 16px;
        font-weight: 900;
        line-height: 1;
    }

    &__label {
        font-size: 14px;
        font-weight: 400;
        color: $ecart-secondary-400;
        line-height: 1;
        position: absolute;
        left: 0;
        top: calc(50% - 7px);
        transition: 200ms all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &--focus {
            color: $general-black;
            font-size: 12px;
            top: 0;
        }
    }

    &__value {
        color: $general-black;
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        line-height: 1;
        overflow: hidden;
        padding-top: 12.5px;
        position: relative;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &__right-content {
        display: flex;
        align-items: center;
    }

    &__clear-button {
        align-items: center;
        border: none;
        border-radius: 100%;
        background-color: transparent;
        color: $ecart-secondary-500;
        display: flex;
        flex-shrink: 0;
        height: 22px;
        justify-content: center;
        margin-right: 2px;
        padding: 2px;
        transition: 200ms all;
        width: 22px;

        &:hover {
            background-color: $ecart-secondary-100;
        }
    }

    &__search-input {
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        padding: 4.5px 8px;
        width: 100%;

        &:focus {
            border-color: #bddb91;
            box-shadow: 0 0 0 0.2rem rgba(#81b23a, 0.25);
        }
    }

    &__option {
        align-items: center;
        background: none;
        border: none;
        border-radius: 8px;
        color: $general-black;
        display: flex;
        gap: 5px;
        padding: 8px;
        text-align: left;
        width: 100%;

        &:hover {
            background-color: #f2f7eb !important;
            color: #81b23a !important;
        }
    }

    &__option-image-container {
        border: 1px solid $ecart-secondary-200;
        border-radius: 6px;
        flex-shrink: 0;
        height: 24px;
        overflow: hidden;
        width: 24px;
    }

    &__option-image {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    &__option-label {
        white-space: nowrap;
    }

    &__list-container {
        background-color: white;
        border: 1px solid $ecart-secondary-100;
        border-radius: 8px;
        box-shadow: $elevation-200;
        left: 0;
        padding: 8px;
        position: absolute;
        width: 100%;
        z-index: 99;
    }

    &__list {
        overflow-y: auto;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        max-height: 250px;
        border-bottom: $ecart-secondary-300;
    }

    &__tags-container {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
    }

    &__tag {
        align-items: center;
        font-size: 14px;
        display: flex;
        gap: 4px;
    }

    &__remove-tag-button {
        align-items: center;
        border: none;
        border-radius: 4px;
        background-color: transparent;
        color: $ecart-secondary-500;
        display: flex;
        flex-shrink: 0;
        height: 18px;
        justify-content: center;
        margin-right: 2px;
        padding: 2px;
        transition: 200ms all;
        width: 18px;

        &:hover {
            background-color: $ecart-secondary-200;
        }
    }

    &__button-container {
        padding-top: 8px;
    }

    &__checkbox-container {
        color: $ecart-primary;
    }
}
</style>